$(document).ready(function(){
		// Objects
	var reel = $('.carousel').find('ul'),
		event = reel.find('li'),
		box = reel.parent(),
		// Numbers
		mobi = 576, tabl = 768, desk = 992, lapt = 1280, port = 1440,
		winX = $(window).innerWidth(),
		rem = 0;
	// Set rem value
	function setRem(){
		var winX = $(window).innerWidth(),
			rem = 0;
		if(winX <= 768)
		{
			return rem = 15;
		} else {
			return rem = 18;
		}
	}
	function isShorterX(n){
		var x = $(window).innerWidth();
		return x <= n;
	}
	function dateReel(){
			// Objects
		var reel = $('.carousel').find('ul'),
			event = reel.find('li'),
			box = reel.parent(),
			prev = box.find('.carousel-counter').children().first(),
			next = box.find('.carousel-counter').children().last(),
			last = reel.find('.last'),
			name = event.find('.event-title'),
			desc = event.find('.event-content'),
			// Numbers
			winX = $(window).innerWidth(),
			rem = 18,
			d = 3,
			x = box.width(),
			y = box.height(),
			m = 1.2 * rem,
			i = event.length,
			c = 0,
			e = event.outerWidth(),
			s = (x / d) - (m / 1.5);
		if(isShorterX(mobi))
		{
			d = 1;
			s = x;
		} else if(isShorterX(tabl)){
			d = 2;
			s = (x / d) - (m / d);
		} else {
			d = 3;
			s = (x / d) - (m / 1.5);
		}
		function refreshChildPosition(){
			event.each(function(){
				$(this).css('left', ((x + m) / d) * event.index($(this)));
			});
		}
		function refreshChildPositionNext(){
			event.each(function(){
				leftVal = parseInt($(this).css('left'));
			});
		}
		// Set elements appearance
		event.css({
			width: s,
			minWidth: s
		}).width(s);
		reel.css({
			minWidth: ((x + m) / d) * i,
			height: y - (rem * 1.2)
		}).children(':last').addClass('last');
		reel.next().find('span:first').addClass('counter--backwards');
		reel.next().find('span:last').addClass('counter--forwards');
		// Get the number from a css string
		function getLeft(x){
			return parseFloat(x.css('left'), 10)
		}
		// Update elements position
		refreshChildPosition();
		// Set forward behaviour
		next.on('click', function(){
			c++;
			reel.finish().animate({
				left: '-='+ (s + m)
			}, 330, function(){
				var lastItem = reel.find('li').first(),
					firstItem = reel.find('li').last();
				lastItem.remove().appendTo(reel);
				lastItem.css('left', getLeft(firstItem) + (s + m));
			});
		});
		// Set backward behaviour
		prev.on('click', function(){
			c--;
			var lastItem = reel.find('li').last();
			lastItem.remove().prependTo(reel);
			lastItem.css('left', ((x + m) / d) * c);
			reel.finish().animate({
				left: '+='+ (s + m)
			}, 330, 'swing');
		});
		function showValues(e, f){
			$.each(e, function(i, v){
				var y = $(this).height(),
					c = $(this).siblings(f);
				console.log(y);
				console.log(f);
				c.css('margin-top', y);
				if(y > 48)
				{
					console.log($(this));
				}
				//return e.innerHeight();
			})
		}
		//showValues(name, desc)
	}
	setRem();
	dateReel();
	$(window).on('resize', function(){
		var winX = $(window).innerWidth();
		setRem();
		dateReel();
	})
});
